/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// h1, h2, h3, h4, h5, h6, ion-title {
//   font-family: 'Nunito', sans-serif;
// }

h1 {
  margin-top: 15px;
  font-size: 25px;
  line-height: 30px;
}

.swiper-pagination-bullets {
  position: fixed;
}

.swiper-pagination-bullet {
  --bullet-background: #000;
  width: 14px;
  height: 14px;
}

.swiper-wrapper {
  //margin-bottom:30px;
}

.button-medium {
  font-size: 0.95em;
  min-height: 45px;
}

.button-large {
  font-size: 30px;
  height: 60px;
}

.button-small {
  font-size: 1em;
}

ion-button {
  // font-family: 'Nunito', sans-serif;
  text-transform: none;
  letter-spacing: 0px;
  // font-size: 1.4em;
  font-weight: bold;
  //min-height: 47px;
  //letter-spacing: 0.1px;
}

.some-margin {
  margin: 14px 25px 14px 25px;
}

.header-md:after {
  display: none;
}

ion-item.cm-ion-item-colored {
  --background: #fafafa;
  --border-color: white;
}

ion-searchbar.cm-shops-searchbar {
  .searchbar-input-container {
    input {
      font-size: 14px;
      padding-right: 40px;
      padding-left: 40px;
    }
    button {
      display: block;
    }
  }
  .searchbar-input {
    box-shadow: 0 3px 1px -2px rgba(255,255,255,1);
  }
}

.cm-shop-filter-modal .modal-wrapper {
   height: 50%;
   width: 80%;
}

.price-cross {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #EF4694;
    position: absolute;
    top: calc(50% - 2px);
    left: 0px;
  }
}

.reward-text {
  font-size: 8px;
  font-weight: bold;
  color: #EF4694;
}

.bg-green {
  --ion-item-background: #00E7BF;
}

.plt-desktop, .plt-mobileweb {
  ion-menu-button {
    display: none !important;
  }

  ion-tab-bar {
    // display: none !important;
  }

  // .cm-favourite {
  //   display: none !important;
  // }
}

.alert-button-group {
  .alert-button {
    margin-bottom: 5px;
  }
}
